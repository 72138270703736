import { Box, Button, Divider, Typography } from "@mui/joy";
import * as ics from "ics";
import moment from "moment";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Paper } from "../atoms/paper";
import MissionMap_Entity, {
  Engagement,
} from "../entities/missionMap/missionMap";
import { EngagementLineItem } from "../molecules/engagementLineItem";
import { useMissionMap } from "../templates/answerTemplate";

export const addToCalendar = (missions: MissionMap_Entity[]) => {
  const events: ics.EventAttributes[] = [];

  missions.forEach((mission) => {
    const engagements = mission?.Engagements.reduce<Engagement[]>((a, v) => {
      const content = v.Content.filter((e) => e.Playing);
      a.push(...content);
      return a;
    }, []);

    engagements?.forEach((engagement) => {
      events.push({
        startInputType: "utc",
        startOutputType: "utc",
        endInputType: "utc",
        endOutputType: "utc",
        status: "CONFIRMED",
        organizer: {
          name: mission!.CompanyName,
        },
        start: moment(engagement.DateFromUTC)
          .utc()
          .format("YYYY-M-D-H-m")
          .split("-")
          .map((e) => parseInt(e)) as ics.DateArray,
        end: moment(engagement.DateToUTC)
          .utc()
          .format("YYYY-M-D-H-m")
          .split("-")
          .map((e) => parseInt(e)) as ics.DateArray,
        title: `${engagement.Title} - ${mission?.ProjectName}`,
        description: `Invited by ${mission?.CompanyName} - ${mission?.ContractorEmail} ${mission?.ContractorPhone}\n`,
        location: engagement.VenueName
          ? `${engagement.VenueAddress}`
          : undefined,
        url: window.location.href,
        geo: {
          lat: Number(engagement.VenueLat),
          lon: Number(engagement.VenueLng),
        },
        categories: ["Rhapsody"],
      });
    });
  });

  ics.createEvents(events, (error: any, value: any) => {
    if (error) {
      console.log(error);
      return;
    }

    const blob = new Blob([value], { type: "text/calendar" });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `rhapsody`;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  });
};

export function Schedule({ token }: { token: string }) {
  const mission = useMissionMap(token);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const addToCalendarRequest = searchParams.get("addToCalendar") === "true";
    searchParams.delete("addToCalendar");
    if (addToCalendarRequest && mission) addToCalendar([mission]);
  }, []);

  return (
    <Paper>
      <Box>
        <Typography level="title-lg">Schedule</Typography>
        <Typography level="body-sm">{mission?.ScheduleDescription}</Typography>
      </Box>
      <Button
        startDecorator={<i className="fa-solid fa-calendar-plus"></i>}
        variant="solid"
        color="neutral"
        fullWidth
        onClick={() => {
          if (mission) addToCalendar([mission]);
        }}
      >
        Add to Calendar
      </Button>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          width: "100%",
        }}
      >
        {mission?.Engagements?.map((e) => {
          if (e.Content.length === 0) return [];
          return (
            <>
              <Divider>{e.Type}</Divider>
              {e.Content.map((c) => (
                <EngagementLineItem
                  key={c.DateFromUTC}
                  engagement={c}
                  token={token}
                />
              ))}
            </>
          );
        })}
      </Box>
    </Paper>
  );
}
